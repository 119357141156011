import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './home.css';

function Home() {
  const [data, setData] = useState({ total: 0, male: 0, female: 0 });
  const [error, setError] = useState(null);
  console.log("hai")
  useEffect(() => {
    axios.get('https://fcosrecruit.rf.gd/api/count.php')
      .then(response => {
        console.log('API Response:', response); // Log the entire response
        if (response.data) {
          setData({
            total: response.data.total ? response.data.total.count : 0,
            male: response.data.male ? response.data.male.count : 0,
            female: response.data.female ? response.data.female.count : 0
          });
        } else {
          setError('No data received');
        }
      })
      .catch(error => {
        console.error('API Error:', error); // Log the error details
        setError('Error fetching data: ' + error.message);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='home'>
      <div className='stat'>
        <center>
          Total: <h1>{data.total}</h1>
        </center>
      </div>
      <div className='stat'>
        <center>
          Male: <h1>{data.male}</h1>
          <br />
          Female: <h1>{data.female}</h1>
        </center>
      </div>
    </div>
  );
}

export default Home;
